import React from 'react';
import Benefit from '../../Elements/Benefit/Benefit';
import SectionTitle from '../../Elements/SectionTitle/SectionTitle';
import './Benefits.scss';
import camera from '../../../assets/img/camera.png';
import time from '../../../assets/img/time.png';
import fit from '../../../assets/img/fit.png';
import learning from '../../../assets/img/learning.png';
import blocks from '../../../assets/img/blocks.png';
import cost from '../../../assets/img/cost1.png';
import certificate from '../../../assets/img/certificate.png';
import { useTranslation } from 'react-i18next';


const Benefits = () => {

    const { t, i18n } = useTranslation('common');

    const benefits = [
        {
            imgSRC: camera,
            imgALT: 'camera',
            benefitTitle: t('benefits.CCTVCameras'),
            benefitDescription: '',

        },
        {
            imgSRC: time,
            imgALT: 'camera',
            benefitTitle: t('benefits.fastImplementation'),
            benefitDescription: '',

        },
        {
            imgSRC: fit,
            imgALT: 'camera',
            benefitTitle: t('benefits.tailorMade'),
            benefitDescription: '',

        },
        {
            imgSRC: learning,
            imgALT: 'camera',
            benefitTitle: t('benefits.constantLearning'),
            benefitDescription: '',

        },
        {
            imgSRC: blocks,
            imgALT: 'camera',
            benefitTitle: t('benefits.addingFeatures'),
            benefitDescription: '',

        },
        {
            imgSRC: cost,
            imgALT: 'camera',
            benefitTitle: t('benefits.paymentOnce'),
            benefitDescription: '',

        },
        {
            imgSRC: certificate,
            imgALT: 'camera',
            benefitTitle: t('benefits.fixedCosts'),
            benefitDescription: '',

        },
    ]
    return (
        <div id='3' className='benefits-container'>
            <div className='benefits'>
                <SectionTitle text={t('menu.benefits')}></SectionTitle>
                <div className='benefits-wrapper'>
                    {benefits.map((benefit) => (
                        <Benefit key={benefit.imgSRC} imgSRC={benefit.imgSRC} imgALT={benefit.imgALT} benefitTitle={benefit.benefitTitle} benefitDescription={benefit.benefitDescription} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Benefits;
