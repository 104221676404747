import React from 'react';
import './ContactPerson.scss';
import singleWave from '../../../assets/img/wave-single-green.svg';

const ContactPerson = ({ photoSRC, name, role, email, phone }) => {

    return (
        <div data-aos="zoom-in" className='contact-person-container'>
            {name === 'Mateusz Zapart' && <img className='wave' src={singleWave} alt='wave' />}
            <img className='person-photo' src={photoSRC} alt='cofounder' />
            {name === 'Andrzej Trznadel' && <img className='rotate-wave' src={singleWave} alt='wave' />}
            <p className='person-name'>{name}</p>
            <p className='person-role'>{role}</p>
            <p className='person-email'>{email}</p>
            <p className='person-phone'>{phone}</p>
        </div>
    )
};

export default ContactPerson;