import React from 'react';
import SectionTitle from '../../Elements/SectionTitle/SectionTitle';
import './Problem.scss';
import wave1 from '../../../assets/img/wave-1.png';
import wave2 from '../../../assets/img/wave-2.png';
import wave3 from '../../../assets/img/wave-3.png';
import manWatch from '../../../assets/img/watch.png';
import { useTranslation } from 'react-i18next';

const Problem = () => {

    const { t, i18n } = useTranslation('common');

    return (
        <div id='1' className='problem-container'>
            <SectionTitle text='AI Safety Solutions:' />

            <div data-aos="fade-right" className='solution-wrapper'>
                <div className='solution-point'>
                    <img src={wave1} alet='wave' />
                    <p>{t('about.nonStop')} </p>
                </div>
                <div className='solution-point'>
                    <img src={wave2} alet='wave' />
                    <p>{t('about.monitoringFree')}</p>

                </div>
                <div className='solution-point'>
                    <img src={wave3} alet='wave' />
                    <p>{t('about.dangerAlert')}</p>
                </div>
            </div>
            <img data-aos="fade-left" className='man-watch-img' src={manWatch} />
        </div>
    )
};

export default Problem;
