import React from 'react';
import './Title.scss';

const Title = () => {

    return (
        <h1 data-aos="fade-right">AI Safety Solutions</h1>
    );
};

export default Title;
