import React from 'react';
import './Footer.scss';
import logo from '../../../assets/img/logo-aiss-long.png';
import { useTranslation } from 'react-i18next';


const Footer = ({ privacy, setPrivacy }) => {

    const { t } = useTranslation('common');


    return (
        <div className='footer-container'>
            <img src={logo} alt='logo-aiss' />
            <button style={{ cursor: 'pointer', zIndex: '2' }} onClick={() => setPrivacy(true)}>{t('privacy.title')}</button>
            <p>© 2022. {t('privacy.allRights')}</p>
        </div>
    )
}

export default Footer;
