import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import slajd1 from '../../../assets/img/slajd1.png'
import slajd2 from '../../../assets/img/slajd2.png'
import slajd3 from '../../../assets/img/slajd3.png'
import slajd4 from '../../../assets/img/slajd4.png'
import slajd5 from '../../../assets/img/slajd5.png'
import slajd6 from '../../../assets/img/slajd6.png'
import slajd7 from '../../../assets/img/slajd7.png'

import slide1 from '../../../assets/img/slide1.png'
import slide2 from '../../../assets/img/slide2.png'
import slide3 from '../../../assets/img/slide3.png'
import slide4 from '../../../assets/img/slide4.png'
import slide5 from '../../../assets/img/slide5.png'
import slide6 from '../../../assets/img/slide6.png'
import slide7 from '../../../assets/img/slide7.png'




const Functionality = ({ activeLang }) => {

    const imgArray = [
        slajd1, slajd2, slajd3, slajd4, slajd5, slajd6, slajd7
    ]

    const imgArrayEN = [
        slide1, slide2, slide3, slide4, slide5, slide6, slide7
    ]

    return (
        <>
            {activeLang === 'pl' &&
                <AwesomeSlider>
                    {imgArray.map((img) => (
                        <div key={img} data-src={img} />
                    ))}

                </AwesomeSlider>
            }
            {activeLang === 'en' &&
                <AwesomeSlider>
                    {imgArrayEN.map((img) => (
                        <div key={img} data-src={img} />
                    ))}

                </AwesomeSlider>
            }
        </>
    )
}

export default Functionality;
