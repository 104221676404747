import React from 'react';
import './NavBar.scss';
import Logo from "../Logo/Logo";
import Menu from '../Menu/Menu';
import MenuMobileButton from '../MenuMobileButton/MenuMobileButton';
import LanguageButtonsWrapper from '../LanguageToggle/LanguageButtonsWrapper/LanguageButtonsWrapper';
import { useState } from 'react';


const NavBar = ({ menuMobile, setMenuMobile, activeLang, setActiveLang }) => {

    return (
        <div className='navbar-container'>
            <Logo />
            <Menu />
            <MenuMobileButton menuMobile={menuMobile} setMenuMobile={setMenuMobile} />
            <LanguageButtonsWrapper activeLang={activeLang} setActiveLang={setActiveLang} />
        </div>
    )
}

export default NavBar;
