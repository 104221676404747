import React, { useEffect, useState } from 'react';
import Subtitle from '../../Elements/Subtitle/Subtitle';
import Title from '../../Elements/Title/Title';
import './Header.scss';
import system from '../../../assets/img/system.png'
import partnersLogo from "../../../assets/img/partners-logo.png";



const Header = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div className='header-container'>
            <div className={scrollPosition > 280 ? ' logoUE-container hide' : 'logoUE-container'}>
                <img src={partnersLogo} alt='eu flag' className={scrollPosition > 0 ? 'hide-img img' : 'img'} />
            </div>
            <div className='titles-wrapper'>
                <Title />
                <Subtitle />
            </div>
            <img data-aos="fade-left" className='system-img' src={system} />
        </div>
    );
};

export default Header;
