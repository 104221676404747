import "./App.scss";
import NavBar from "./app/Elements/NavBar/NavBar";
import Header from "./app/Sections/Header/Header";
import Functionalities from "./app/Sections/Functionalities/Functionalities";
import Benefits from "./app/Sections/Benefits/Benefits";
import Contact from "./app/Sections/Contact/Contact";
import Footer from "./app/Sections/Footer/Footer";
import Problem from "./app/Sections/Problem/Problem";
import Cookies from "./app/Elements/Cookies/Cookies";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Privacy from "./app/Elements/Privacy/Privacy";
import MenuMobile from "./app/Elements/MenuMobile/MenuMobile";
import Partners from "./app/Sections/Partners/Partners";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation("common");

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const [privacy, setPrivacy] = useState(false);
  const [menuMobile, setMenuMobile] = useState(false);
  const [activeLang, setActiveLang] = useState("pl");

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("aissLang"));
    setActiveLang(localStorage.getItem("aissLang"));
    if (!localStorage.getItem("aissLang")) {
      localStorage.setItem("aissLang", "pl");
    } else return;
  }, []);

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <NavBar
        menuMobile={menuMobile}
        setMenuMobile={setMenuMobile}
        activeLang={activeLang}
        setActiveLang={setActiveLang}
      />
      <Header />
      <Problem />
      {/* <About /> */}
      <Functionalities activeLang={activeLang} />
      <Benefits />
      <Contact />
      <Partners />
      <Footer privacy={privacy} setPrivacy={setPrivacy} />
      <Cookies privacy={privacy} setPrivacy={setPrivacy} />
      {privacy && <Privacy setPrivacy={setPrivacy} />}
      {menuMobile && <MenuMobile />}
    </div>
  );
}

export default App;
