import React from 'react';
import { useTranslation } from 'react-i18next';

import './Privacy.scss'

const Privacy = ({ setPrivacy }) => {

    const { t } = useTranslation('common');


    return (

        <div className="privacy-container">
            <div className='privacy-wrapper'>
                <span onClick={() => setPrivacy(false)} className='x'>x</span>
                <h1>{t('privacy.subtitle')}</h1>
                <div data-aos='zoom-in' className='rules'>
                    <p>{t('privacy.inPrivacy')} („<span className='bold'>{t('privacy.title')}</span>”) {t('privacy.description-1')}</p>
                    <p>
                        {t('privacy.description-2')}
                    </p>
                    <p>
                        {t('privacy.description-3')}</p>
                    <ul>
                        <li className='point-x'>
                            {t('privacy.definitions.title')}
                            <li className='point-x-x'>1. <span className='bold'> {t('privacy.definitions.admin')}</span>  {t('privacy.definitions.adminDesc')}</li>
                            <li className='point-x-x'>2. <span className='bold'>C{t('privacy.definitions.cookies')}</span> {t('privacy.definitions.cookiesDesc')}</li>
                            <li className='point-x-x'>3. <span className='bold'>{t('privacy.definitions.client')}</span> {t('privacy.definitions.clientDesc')}
                            </li>
                            <li className='point-x-x'>4. <span className='bold'>{t('privacy.definitions.side')}</span> {t('privacy.definitions.sideDesc')}</li>
                            <li className='point-x-x'>5.
                                <span className='bold'> {t('privacy.definitions.device')}</span> {t('privacy.definitions.deviceDesc')}
                            </li>
                            <li className='point-x-x'>6. <span className='bold'>{t('privacy.definitions.rodo')}</span> {t('privacy.definitions.rodoDesc')}</li>
                        </li>
                        <li className='point-x'>
                            {t('privacy.dataProtection.title')}
                            <li className='point-x-x'>{t('privacy.dataProtection.1')} </li>
                            <li className='point-x-x'>{t('privacy.dataProtection.2')}
                                <li className='point-x-x-x'>{t('privacy.dataProtection.2a')}</li>
                                <li className='point-x-x-x'>{t('privacy.dataProtection.2b')}</li>
                                <li className='point-x-x-x'>{t('privacy.dataProtection.2c')}</li>
                            </li>
                            <li className='point-x-x'>{t('privacy.dataProtection.3')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.4')} </li>
                            <li className='point-x-x'>{t('privacy.dataProtection.5')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.6')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.7')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.8')} </li>
                            <li className='point-x-x'>{t('privacy.dataProtection.9')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.10')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.11')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.12')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.13')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.14')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.15')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.16')}</li>
                            <li className='point-x-x'>{t('privacy.dataProtection.17')}</li>

                        </li>
                        <li className='point-x'>
                            {t('privacy.cookies.title')}
                            <li className='point-x-x'>{t('privacy.cookies.1')}</li>
                            <li className='point-x-x'>{t('privacy.cookies.2')}</li>
                            <li className='point-x-x'>{t('privacy.cookies.3')}</li>
                            <li className='point-x-x'>{t('privacy.cookies.4')}
                                <li className='point-x-x-x'>{t('privacy.cookies.4a')}</li>
                                <li className='point-x-x-x'>{t('privacy.cookies.4b')}</li>

                            </li>
                            <li className='point-x-x'>{t('privacy.cookies.5')}
                                <li className='point-x-x-x'>{t('privacy.cookies.5a')}</li>
                                <li className='point-x-x-x'>{t('privacy.cookies.5b')}</li>
                            </li>
                            <li className='point-x-x'>{t('privacy.cookies.6')}</li>
                            <li className='point-x-x'>{t('privacy.cookies.7')}</li>
                            <li className='point-x-x'>{t('privacy.cookies.8')}</li>
                            <li className='point-x-x'>{t('privacy.cookies.9')} </li>
                            <li className='point-x-x'>{t('privacy.cookies.10')}</li>
                        </li>
                    </ul>
                </div>
            </div>
        </div >
    );
};

export default Privacy;
