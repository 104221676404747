import React from 'react';
import { useTranslation } from 'react-i18next';
import './Subtitle.scss';

const Subtitle = () => {

    const { t, i18n } = useTranslation('common');

    return (
        <h2 data-aos="fade-right" className='subtitle-container'>{t('title')}</h2>
    );
};

export default Subtitle;
