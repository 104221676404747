import React from 'react';
import { useTranslation } from 'react-i18next';
import Functionality from '../../Elements/Functionality/Functionality';
import SectionTitle from '../../Elements/SectionTitle/SectionTitle';
import './Functionalities.scss';

const Functionalities = ({ activeLang }) => {

    const { t, i18n } = useTranslation('common');

    return (
        <div data-aos='zoom-in' id='2' className='functionalities-container'>
            <SectionTitle text={t('menu.features')}></SectionTitle>

            <Functionality activeLang={activeLang} />

        </div>
    )
}

export default Functionalities;
