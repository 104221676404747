import React from 'react';
import './Benefit.scss';

const Benefit = ({ imgSRC, imgALT, benefitTitle, benefitDescription }) => {

    return (
        <div className='benefit-container'>
            <div data-aos="zoom-in" className='benefit-img-wrapper'>
                <img className='benefit-img' src={imgSRC} alt={imgALT} />
            </div>
            <h5 data-aos="zoom-in" className='benefit-title'>{benefitTitle}</h5>
        </div>
    )
};

export default Benefit;