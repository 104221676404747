import React from 'react';
import './Logo.scss';
import logo from '../../../assets/img/logo-aiss-long.png'
import logoUE from "../../../assets/img/logo-4.webp";


const Logo = () => {

    return (
        <>
            <a href='/'>
                <img className='logo-container' src={logo} alt='logo aiss' />
            </a>

        </>
    );
};

export default Logo;
