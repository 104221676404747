import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuElement from '../MenuElement/MenuElement';
import './Menu.scss';

const Menu = () => {

    const { t } = useTranslation('common');


    return (
        <ul className='menu-container'>
            <MenuElement text={t('menu.whatIsAISS')} id={'#1'} />
            <MenuElement text={t('menu.features')} id={'#2'} />
            <MenuElement text={t('menu.benefits')} id={'#3'} />
            <MenuElement text={t('menu.contact')} id={'#4'} />
        </ul>);
};

export default Menu;
