import React, { useState } from 'react';
import SectionTitle from '../../Elements/SectionTitle/SectionTitle';
import './Partners.scss';
import partnersLogo from '../../../assets/img/banner-ncbir.webp'
import netrix from '../../../assets/img/logo-netrix-ventures.webp'
import { useTranslation } from 'react-i18next';


const Partners = () => {

    const { t, i18n } = useTranslation('common');

    const [showMore, setShowMore] = useState(false)

    return (
        <div className='partners-container'>
            <SectionTitle text={t('coFounding.basic')} />
            <div className='partners-logo-container'>
                <img data-aos="zoom-in" className='partners-logo' src={partnersLogo} alt={'partners logo'} />
            </div>
            <img data-aos="zoom-in" className='netrix-venture-logo' src={netrix} alt={'netrix venture logo'} />
            <p data-aos="zoom-in" className='project-text'>{t('coFounding.extras')}</p>
            {showMore ? <div data-aos="zoom-in" className='partners-details'>
                <p>{t('coFounding.more.description')} <span>{t('coFounding.more.description-bold')}</span></p>
                <p><span>{t('coFounding.more.purpose')}</span>{t('coFounding.more.purposeDesc')}</p>
                <p><span>{t('coFounding.more.value')}</span> {t('coFounding.more.valueDesc')}</p>
                <p><span>{t('coFounding.more.coFounding')}</span> {t('coFounding.more.coFoundingDesc')}</p>
                <p><span>{t('coFounding.more.priority')}</span> {t('coFounding.more.priorityDesc')}</p>
                <p><span>{t('coFounding.more.action')}</span> {t('coFounding.more.actionDesc')}</p>
                <p><span>{t('coFounding.more.subAction')}</span> {t('coFounding.more.subActionDesc')}</p>
            </div> : null}
            <button onClick={() => setShowMore(!showMore)} className='read-more'>{!showMore ? t('readMore') : t('hide')}</button>
        </div>
    )
};

export default Partners;
