import React from 'react';
import './Contact.scss';
import SectionTitle from '../../Elements/SectionTitle/SectionTitle';
import ContactPerson from '../../Elements/ContactPerson/ContactPerson';
import mZapart from '../../../assets/img/mZapart.png'
import aTrznadel from '../../../assets/img/aTrznadel.png'
import { useTranslation } from 'react-i18next';



const Contact = () => {

    const { t, i18n } = useTranslation('common');

    return (
        <div id='4' className='contact-container'>
            <SectionTitle text={t('contact')}></SectionTitle>
            <div className='cofounders-container'>
                <ContactPerson photoSRC={mZapart} name='Mateusz Zapart' role='Product Owner, BizDev, co-founder' email='mateusz.zapart@gmail.com' phone='501 101 045' />

                <ContactPerson photoSRC={aTrznadel} name='Andrzej Trznadel' role='Product Manager, co-founder' email='andrzej@granpol.com' phone='604 442 121' />
            </div>
        </div>
    )
}

export default Contact;
