import React from 'react';
import './MenuElement.scss';
import waveGreenLight from '../../../assets/img/wave-green-light.png';


const MenuElement = ({ text, id }) => {

    return (
        <li className='menu-element'>
            <img className='wave-green-light' src={waveGreenLight} />
            <a href={id}>{text}</a>
        </li>);
};

export default MenuElement;
