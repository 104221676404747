import React from 'react';
import './MenuMobileButton.scss';

const MenuMobileButton = ({ menuMobile, setMenuMobile }) => {

    return (

        <div onClick={() => setMenuMobile(!menuMobile)} className='menu-mobile-button'>
            <span className='menu-line'></span>
            <span className='menu-line'></span>
            <span className='menu-line'></span>
        </div>

    )
};

export default MenuMobileButton;
