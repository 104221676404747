import React, { useEffect } from 'react';
import './MenuMobile.scss';
import AOS from "aos";
import "aos/dist/aos.css";
import MenuElement from '../MenuElement/MenuElement';
import { useTranslation } from 'react-i18next';

const MenuMobile = () => {
    const { t, i18n } = useTranslation('common');

    useEffect(() => {
        AOS.init({
            duration: 400,
        });
    }, []);
    return (

        <ul className='menu-mobile-container'>

            <MenuElement text={t('menu.whatIsAISS')} id={'#1'} />
            <MenuElement text={t('menu.features')} id={'#2'} />
            <MenuElement text={t('menu.benefits')} id={'#3'} />
            <MenuElement text={t('menu.contact')} id={'#4'} />

        </ul>

    )
};

export default MenuMobile;
