import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageButton.scss';


const LanguageButton = ({ lang, activeLang, setActiveLang }) => {

    const { i18n } = useTranslation("common");

    const handleLanguageButton = (lang) => {
        localStorage.setItem("aissLang", lang);
        i18n.changeLanguage(lang);
        setActiveLang(lang);
    }
    return (
        <button
            style={{ color: activeLang === lang ? "#0fa" : "" }}
            className='language-button' onClick={() => handleLanguageButton(lang)}>{lang}</ button>
    )
}

export default LanguageButton;
