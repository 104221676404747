import React from 'react';
import './SectionTitle.scss';

const SectionTitle = ({ text, color }) => {

    return (
        <h3 data-aos="zoom-in" style={{ color: color === 'light' ? '#fff' : '' }} className='section-title'>{text}</h3>

    )
};

export default SectionTitle;
