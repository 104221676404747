import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageButton from '../LanguageButton/LanguageButton';
import './LanguageButtonsWrapper.scss';

const LanguageButtonsWrapper = ({ activeLang, setActiveLang }) => {

    const { t } = useTranslation('common');


    return (
        <div className='language-buttons-wrapper'>
            <LanguageButton lang='pl' setActiveLang={setActiveLang} activeLang={activeLang} />
            <LanguageButton lang='en' setActiveLang={setActiveLang} activeLang={activeLang} />
        </div>
    )
}

export default LanguageButtonsWrapper;
