import React, { useEffect, useState } from 'react';
import './Cookies.scss'

const Cookies = ({ privacy, setPrivacy }) => {
    const [cookiesStyle, setCookiesStyle] = useState("");
    const handleCookiesClick = () => {
        localStorage.setItem('cookiesAISS', 'true')
        setCookiesStyle('none')
    }

    return (
        localStorage.getItem('cookiesAISS') !== 'true' &&
        <div className="cookies-container">
            <p>
                Korzystamy z ciasteczek! Zapoznaj się z naszą
                <span onClick={() => setPrivacy(true)}>polityką prywatności</span>.
            </p>
            <button style={{ display: cookiesStyle }} onClick={() => handleCookiesClick()}>
                Zgadzam się
            </button>
        </div >
    );
};

export default Cookies;
